<i18n>
  {
    "es": {
      "HAVE_COUPON": "Canjea aquí un código de descuento",
      "TEXT_COUPON": "Introdúcelo ahora mismo :)",
      "COUPON_PLACEHOLDER": "Introduce el código",
      "APPLY": "APLICAR",
      "COUPON_INFO": "Hay cupones en tu cesta que no estan activos, revisa las condiciones de la promocion.",
      "ERROR_ADDING_COUPON": "Error al añadir el cupón",
      "COUPON_ADDED": "Cupón añadido correctamente",
    },
    "en": {
      "HAVE_COUPON": "Redeem a discount code or gift card here",
      "TEXT_COUPON": "Enter it right now :)",
      "COUPON_PLACEHOLDER": "Enter the code",
      "APPLY": "APPLY",
      "COUPON_INFO": "There are coupons in your cart that are not active, check the promotion conditions.",
      "ERROR_ADDING_COUPON": "Error adding coupon",
      "COUPON_ADDED": "Coupon added successfully",
    }   
  }
</i18n>

<template>
  <div id="cart-coupons" clas ref="couponDiv" class="px-4">
    <div class="flex items-start bg-white">
      <div class="flex-1">
        <div
          class="flex items-start justify-between text-sm font-bold text-others-black mb-2.5"
        >
          <div class="text-sm font-semibold tracking-wider leading-5">
            <div
              v-if="loading"
              class="flex flex-col justify-start items-start gap-y-1 w-full"
            >
              <div class="w-72 h-4 rounded-lg bg-[#d8d8d8]"></div>
              <div class="w-32 h-4 rounded-lg bg-[#d8d8d8]"></div>
            </div>
            <div v-else>{{ t('HAVE_COUPON') }}</div>
          </div>
          <div>
            <div v-if="loading" class="w-4 h-4 rounded-full bg-[#d8d8d8]"></div>
            <button v-else @click="couponBox = !couponBox">
              <img
                v-if="couponBox"
                src="@/assets/svg/icons/icons-arrow-up.svg"
                class="h-6 w-6"
              />
              <img
                v-else
                src="@/assets/svg/icons/icons-arrow-down-black.svg"
                class="h-6 w-6"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="loading"
        class="w-full h-14 my-6 rounded-lg bg-[#d8d8d8]"
      ></div>
      <div v-else>
        <div v-if="couponBox" class="overflow-hidden my-6">
          <div
            class="my-1 flex items-center justify-between border border-others-grey3 rounded bg-white"
          >
            <input
              @focus="adjustScroll"
              @input="updateInput"
              type="text"
              id="coupon"
              name="coupon"
              :value="coupon"
              :placeholder="t('COUPON_PLACEHOLDER')"
              class="h-14 flex-1 appearance-none rounded-lg pl-4 text-sm focus:outline-none text-sanremo-black font-medium tracking-wider"
            />
            <button
              v-if="applicable"
              @click="addCoupon"
              class="px-4 text-sm text-others-black font-bold"
            >
              {{ t('APPLY') }}
            </button>
          </div>

          <div
            class="scrollbar-hidden overflow-x-auto mt-4"
            v-if="coupons.length"
          >
            <div class="inline-flex space-x-4 text-sm">
              <CartCoupon
                v-for="coupon in coupons"
                :key="coupon.id"
                :code="coupon.code"
                :enabled="coupon.active"
                @remove="removeCoupon(coupon)"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="loading" class="my-1.5 h-px bg-[#d8d8d8] mb-8"></div>
    <div v-else class="my-1.5 h-px bg-others-black mb-8"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  coupons: {
    type: Array,
    default: () => [],
  },
  loading: Boolean,
})

const { addMessage } = useSnackBar()

const emit = defineEmits(['handleLoading'])

const { t } = useI18n({
  useScope: 'local',
})

const couponBox = ref(true)
const coupon = ref('')

watch(coupon, (newCoupon, prevCoupon) => {
  coupon.value = newCoupon.toUpperCase()
})

const updateInput = (event) => {
  coupon.value = event.target.value
}

const applicable = computed(() => {
  return coupon.value != ''
})

const couponDiv = ref(null)

const adjustScroll = () => {
  var element = couponDiv.value
  element.scrollIntoView()
}

const addCoupon = async () => {
  emit('add', coupon.value)
  coupon.value = ''
}

const removeCoupon = async (selectedCoupon) => {
  emit('remove', selectedCoupon)
}
</script>
