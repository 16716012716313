<i18n>
  {
    "es": {
      "LARGE_SHIPMENT": "Envío de Larga Duración*",
      "CURRENCY": "€",
      "WISH_LIST": "Lista de Deseos",
      "REMOVE": "Eliminar",
      "ITEM_ADDED": 'Producto añadido correctamente a la cesta',
      "ITEM_REMOVED": 'Producto eliminado correctamente de la cesta',
      "ERROR": "Upsss, algo ha fallado. Vuelva a intentarlo mas tarde",
      "FREE": "Compra 1 y llévate otro gratis",
      "SAVE": "Ahorras",
      "INFO_ICON": "Icono de información"
    },
    "en": {
      "LARGE_SHIPMENT": "Long Duration Shipment*",
      "CURRENCY": "€",
      "WISH_LIST": "Wish List",
      "REMOVE": "Remove",
      "ITEM_ADDED": 'Product successfully added to the cart',
      "ITEM_REMOVED": 'Product successfully removed from the cart',
      "ERROR": "Oops, something went wrong. Please try again later",
      "FREE": "Buy 1 Get one free",
      "SAVE": "Save",
      "INFO_ICON": "Info icon"
    }
  }
</i18n>

<template>
  <div class="item mb-2.5 bg-white py-2.5 px-4">
    <div
      v-if="item.promotion"
      class="bg-gray-100 rounded-md flex items-center gap-x-2 px-4 py-3 mb-4 mt-2"
    >
      <NuxtImg :src="item.promotion.icon" alt="" class="w-4 h-4" />
      <div class="w-full flex justify-between items-center">
        <p
          class="text-xxs xs:text-xs tracking-widest font-semibold text-sanremo-black"
        >
          {{ item.promotion.title }}
        </p>
        <button v-if="item.promotion.url" @click="promoInfoModal = true">
          <img
            src="@/assets/svg/icons/icons-promo-info.svg"
            :alt="t('INFO_ICON')"
            class="h-3 w-3"
          />
        </button>
      </div>
    </div>
    <div class="flex items-start">
      <div
        v-if="loading"
        class="w-20 h-20 mr-2.5 rounded-lg bg-[#d8d8d8]"
      ></div>
      <div v-else class="mr-2.5">
        <div v-if="!item?.seotag" class="mr-2.5 h-20 w-20">
          <img class="object-contain" :src="item.img" width="80" height="80" />
        </div>
        <NuxtLink
          @click="hideCart"
          :to="`/${locale}/${item?.seotag}`"
          class="mr-2.5 h-20 w-20"
        >
          <img class="object-contain" :src="item.img" width="80" height="80" />
        </NuxtLink>
      </div>
      <div class="flex flex-col flex-1">
        <div v-if="loading" class="w-24 h-4 rounded-lg bg-[#d8d8d8]"></div>
        <a v-else class="text-xs uppercase font-bold tracking-widest">{{
          item?.manufacturer_name
        }}</a>

        <div
          v-if="loading"
          class="my-1 pr-5 w-16 h-4 rounded-lg bg-[#d8d8d8]"
        ></div>
        <div v-else>
          <p
            v-if="!item?.seotag"
            class="my-1 pr-5 text-xs tracking-wider font-medium"
          >
            {{
              item?.variant_group_name
                ? item?.variant_group_name
                : item?.short_name
                  ? item?.short_name
                  : item?.name
            }}
          </p>
          <NuxtLink
            v-else
            @click="hideCart"
            :to="`/${locale}/${item?.seotag}`"
            class="my-1 pr-5 text-xs tracking-wider font-medium hover:underline hover:text-blue-700 hover:underline-offset-2"
          >
            {{
              item?.variant_group_name
                ? item?.variant_group_name
                : item?.short_name
                  ? item?.short_name
                  : item?.name
            }}
          </NuxtLink>
        </div>
        <div
          v-if="loading"
          class="my-1 pr-5 w-12 h-3 rounded-lg bg-[#d8d8d8]"
        ></div>
        <div v-else class="my-1 pr-5 text-xs tracking-wider">
          {{ item?.presentation }}
        </div>
        <div class="pt-2 flex items-center justify-between">
          <div
            v-if="loading"
            class="flex items-center w-20 h-11 mt-1 px-3 py-6 rounded bg-[#d8d8d8]"
          ></div>
          <div
            v-else
            class="flex w-20 h-11 items-center rounded border border-others-grey3 px-3 py-6"
          >
            <div class="flex-1 font-lato text-xsplus text-neutral-grey-2">
              {{ quantity }}
            </div>
            <select
              class="block h-5 w-5 grow-0 appearance-none overflow-hidden bg-[url('@/assets/svg/icons/grey/arrow-down.svg')] pl-6 outline-none"
              v-model="quantity"
              @change="handleChange"
              id="quantity"
              name="quantity"
            >
              <option
                v-for="num in item?.max_items ?? 99"
                :key="num"
                :value="num"
              >
                {{ num }}
              </option>
            </select>
          </div>
          <div class="text-sm font-semibold">
            <div v-if="loading">
              <div class="mt-1 w-20 h-3 rounded-lg bg-[#d8d8d8] mr-4"></div>
              <div class="flex justify-end mt-1">
                <div class="w-12 h-4 rounded-lg bg-[#d8d8d8] mr-4"></div>
              </div>
              <div class="flex justify-end mt-1 mr-4">
                <div class="w-16 h-3 rounded-lg bg-[#d8d8d8]"></div>
              </div>
            </div>

            <div v-else>
              <div
                v-if="discount && item.price_policy !== 'ONLY_EFFECTIVE_PRICE'"
              >
                <div class="text-xs font-semibold space-x-2 tracking-wider">
                  <span>PVR</span>

                  <span class="line-through"
                    >{{ formatPrice(item?.pvp) }} {{ currency.symbol }}</span
                  >
                </div>

                <span
                  class="mt-1 flex justify-end text-sm text-[#ac145a] tracking-wider"
                >
                  {{ formatPrice(item?.price) }} {{ currency.symbol }}
                </span>

                <span
                  class="mt-1 flex justify-end text-xs font-light tracking-wider"
                >
                  {{ t('SAVE') }} {{ discountPercentage }}%
                </span>
              </div>
              <span
                v-else
                class="mt-1 flex justify-end text-sm text-[#ac145a] tracking-wider"
              >
                {{ formatPrice(item?.price) }} {{ currency.symbol }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex justify-between mb-2 pr-8">
          <div
            v-if="loading"
            class="flex mt-10 items-center gap-x-2 w-32 h-3 rounded-lg bg-[#d8d8d8]"
          ></div>
          <button
            v-else
            type="button"
            @click="emit('favourite')"
            class="flex mt-10 items-center gap-x-2 text-xs tracking-wider underline font-light underline-offset-4"
          >
            <div class="w-[16px] h-[16px]">
              <img
                src="@/assets/svg/icons/icons-favourite.svg"
                class="w-full h-full"
              />
            </div>
            {{ t('WISH_LIST') }}
          </button>
          <div
            v-if="loading"
            class="flex mt-10 items-center gap-x-2 justify-end w-16 h-3 rounded-lg bg-[#d8d8d8]"
          ></div>
          <button
            v-else
            :aria-placeholder="t('REMOVE')"
            type="button"
            class="flex items-center mt-10 gap-x-2 text-xs tracking-wider underline font-light underline-offset-4"
            @click="handleRemoveItem(item.qty)"
          >
            <div class="w-[16px] h-[16px]">
              <img
                src="@/assets/svg/icons/icons-paperbin.svg"
                class="w-full h-full"
              />
            </div>
            {{ t('REMOVE') }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="!isLastItem" class="my-2.5 h-px bg-neutral-light-grey-2"></div>

    <!-- TODO CHANGE THIS MOCKED TEXT TO DINAMIC PROPERTY TO DISPLAY THE PROMO TEXT -->
    <ClientOnly>
      <ModalPromoInfo
        :visibility="promoInfoModal"
        :promo="mockedPromoText"
        @close="promoInfoModal = false"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
import { formatPrice } from '@/utils/helpers'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  currency: {
    type: Object,
    required: true,
  },
  isLastItem: Boolean,
  loading: Boolean,
})

const { hide: hideCart } = useCart()

const quantity = ref(props.item?.qty)
const promoInfoModal = ref(false)
const mockedPromoText = ref(
  'Disfruta de un 20% de descuento adicional en este producto. La oferta es válida solo por tiempo limitado.'
)

const discount = computed(() => {
  return props.item?.pvp > props.item?.price
})
const discountPercentage = computed(() => {
  return Math.round(
    ((props.item?.pvp - props.item?.price) / props.item?.pvp) * 100
  )
})

const { locale } = useI18n()
const { t } = useI18n({
  useScope: 'local',
})

const emit = defineEmits(['update', 'remove', 'favourite'])

const handleChange = () => {
  emit('update', quantity.value)
}

const handleRemoveItem = async (qty) => {
  // Si se le da al -, se pasa qty = 1
  // Si se le da a la papelera, se pasa qty = totalItemQty
  emit('remove', qty)
}
</script>
